import Events from "kt_jsgem/lib/events";

class KTWrapper {
  constructor() {
    Object.assign(this, Events);
  }

  performCheck({ service, textElement: html, languageLevel, checkType, user, documentName, annotator, annotationTypes }) {
    this.trigger("beforeCheck", html);
    const plainText = annotator.toPlainText();

    this.trigger("checking", html);
    this.trigger("plainText", plainText);
    return service.analyze(plainText, { languageLevel, checkType, user, documentName }).then((response) => {
      const { options, problemSizes, assignments, readingTime } = response;
      const annotations = response.annotations.filter((annotation) => annotationTypes.find((at) => at.id === annotation.typeId));
      this.trigger("parsed", annotations, Object.assign({}, options, { checkType }), problemSizes, assignments, readingTime);
      this.trigger("beforeChange", html);
      return annotator.annotate(annotations).then(() => (response));
    }).then(({ options, checkType }) => {
      this.trigger("done", html, Object.assign({}, options, { checkType }));
      this.trigger("change", html);
    }
    ).catch((e) => {
      this.trigger("error", e);
      this.trigger("done", html, {});
      this.trigger("change", html);
    });
  }

  clearAnnotations(textElementWrapper) {
    this.trigger("beforeChange", textElementWrapper.textElement);
    textElementWrapper.clearAnnotations();
    this.trigger("change", textElementWrapper.textElement);
    this.trigger("clear", textElementWrapper.textElement);
  }

  replaceAnnotation(textElementWrapper, annotation, replacement) {
    this.trigger("beforeReplace", textElementWrapper.textElement, annotation.id, replacement);
    this.trigger("beforeChange", textElementWrapper.textElement);

    textElementWrapper.replaceAnnotation(annotation, replacement);

    this.trigger("change", textElementWrapper.textElement);
  }
}

export default KTWrapper;
