import FetchTransport from "kt_jsgem/lib/transports/fetch_transport";

const Transports = {};
Transports.TRANSPORTS = [FetchTransport];

const findTransport = (url) => {
  for (const transport of Transports.TRANSPORTS) {
    if (transport.shouldUse(url)) {
      return transport;
    }
  }

  throw new Error("Could not find usable transport class!");
};

Transports.performRequest = function(url, xmlString, options) {
  return findTransport(url).performRequest(url, xmlString, options);
};

export default Transports;
