/* global KTEditor */

import "core-js/stable";
import "whatwg-fetch";

import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";

import "kt_jsgem/ckeditor";

import CKEDITOR from "ckeditor";

import React from "react";
import ReactDOM from "react-dom";

document.addEventListener("DOMContentLoaded", () => {
  const getCkeditor = (element) => CKEDITOR.instances[element.dataset.target];

  document.querySelectorAll("button.reset").forEach((element) => {
    element.addEventListener("click", function() {
      const editor = getCkeditor(this);
      if (editor != null) {
        editor.ktPluginInstance.ktEditor.reset();
        editor.setData("");
      }
    });
  });

  document.querySelectorAll("button.copy").forEach((element) => {
    element.addEventListener("click", (event) => {
      const ckEditor = getCkeditor(event.target);
      ckEditor.ktPluginInstance.ktEditor.textElementWrapper.copyTextToClipboard();
    });
  });

  document.querySelectorAll("button.removeAnnotations").forEach((element) => {
    element.addEventListener("click", (event) => {
      getCkeditor(event.target).ktPluginInstance.ktEditor.clearAnnotations();
      getCkeditor(event.target).ktPluginInstance.ktEditor.render();
    });
  });

  CKEDITOR.on("instanceReady", ({editor}) => {
    const element = document.querySelector(`.lastCheck[data-target=${editor.name}]`);
    const featureReadingTime = "readingTime" in element.dataset;
    let displayingReadingTime = false;

    const renderLastCheck = (timestamp) => {
      ReactDOM.render(
        React.createElement(KTEditor.LastCheckComponent, {
          translations: editor.config.extraConfig.KT.translations,
          lastCheckTimestamp: timestamp
        }), element
      );
    };

    const renderReadingTime = (readingTime, lastCheck) => {
      ReactDOM.render(
        React.createElement(KTEditor.ReadingTimeComponent, {
          readingTime,
          lastCheckTimestamp: lastCheck
        }), element
      );
    };

    editor.ktPluginInstance.ktEditor.kt.on("parsed", (_annotations, options, _problemSizes, _assignments, readingTime) => {
      displayingReadingTime = options.checkType === "readability";

      if(featureReadingTime && displayingReadingTime) {
        renderReadingTime(readingTime, new Date());
      } else {
        renderLastCheck(new Date());
      }
    });

    editor.ktPluginInstance.ktEditor.onReset = () => {
      if(featureReadingTime && displayingReadingTime) {
        renderReadingTime(null, null);
      }
    };

    const configReady = (ktConfig) => {
      const hasReadingTimeModule = ktConfig.annotationCategories.some((annotationCategory) => annotationCategory.id === "readability");

      if(featureReadingTime && hasReadingTimeModule) {
        renderReadingTime(null, null);
      } else {
        renderLastCheck(null);
      }
    };

    if(editor.ktPluginInstance.ktEditor.ktConfig) {
      configReady(editor.ktPluginInstance.ktEditor.ktConfig);
    } else {
      const initialConfigLoaded = editor.ktPluginInstance.ktEditor.onConfigLoaded;

      editor.ktPluginInstance.ktEditor.onConfigLoaded = () => {
        configReady(editor.ktPluginInstance.ktEditor.ktConfig);

        editor.ktPluginInstance.ktEditor.onConfigLoaded = initialConfigLoaded;
        initialConfigLoaded();
      };
    }
  });
});
