const FetchTransport = {
  shouldUse() {
    return window.fetch;
  },

  performRequest(url, xmlString, options) {
    const fetchOptions = {
      method: options.method || "POST",
      headers: {
        Accept: "application/xml",
        ...FetchTransport.csrfTokenHeader(url),
        ...FetchTransport.authenticationHeaders(options)
      }
    };

    if(options.method !== "GET") {
      fetchOptions.body = xmlString;
      fetchOptions.headers["Content-Type"] = "application/xml";
    }

    return fetch(url, fetchOptions).then((response) => {
      if(response.ok) {
        return response.text();
      }else{
        return Promise.reject(response);
      }
    }).then((responseText) => {
      return new DOMParser().parseFromString(responseText, "application/xml");
    });
  },

  csrfTokenHeader(url) {
    const headers = {};
    if(url.startsWith("/")) {
      const csrfMeta = document.querySelector("meta[name=csrf-token]");
      if(csrfMeta != null && csrfMeta.content != null) {
        headers["X-CSRF-Token"] = csrfMeta.content;
      }
    }
    return headers;
  },

  authenticationHeaders(options) {
    const headers = {};
    if(options.user) {
      const {
        user
      } = options;

      if(user.username && user.password) {
        headers["Authorization"] = `Basic ${btoa(`${user.username}:${user.password}`)}`;
      }

      if(user.sessionId) {
        headers["X-Kt-Sessionid"] = user.sessionId;
      }
    }
    return headers;
  }
};

export default FetchTransport;
